<template>
  <div class="w">
    <div class="crumbs">
      <router-link to="/">首页</router-link>
      <span>/</span>
      便民服务
      <span>/</span>
      <span style="color: #07C160">文件下载</span>
    </div>
    <div class="types">
      <div :class="{active: !newsCategory}" @click="changeCategory(0)">全部</div>
      <div :class="{active: newsCategory === 1}" @click="changeCategory(1)">政策法规</div>
      <div :class="{active: newsCategory === 2}" @click="changeCategory(2)">通知公告</div>
      <div :class="{active: newsCategory === 3}" @click="changeCategory(3)">技术标准</div>
      <div :class="{active: newsCategory === 4}" @click="changeCategory(4)">系统说明</div>
    </div>
    <ul class="list">
      <li class="item" v-for="item of list" :key="item.id">
        <div class="img">
          <img :src="getImgUrl(item.fileUrl)">
        </div>
        <div class="content">
          <div class="title">{{item.subject}}</div>
          <div class="btn">
            <a :href="item.fileUrl" :download="item.subject" target="_blank"><i></i>下载</a>
          </div>
        </div>
      </li>
    </ul>
    <div class="pagebox">
      <el-pagination
        layout="prev, pager, next"
        :page-size="12"
        :current-page="pageNo"
        @current-change="pageChange"
        :total="total">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, onMounted } from 'vue'
import { ElPagination } from 'element3'
import { yncyArchive } from '../api'
import doc from '../assets/doc.png'
import pdf from '../assets/pdf.png'
import xlsx from '../assets/xlsx.png'

export default defineComponent({
  name: 'PeriodicalList',
  components: {
    ElPagination
  },
  setup () {
    const list = ref([])
    const pageNo = ref(1)
    const total = ref(0)
    const newsCategory = ref('')

    const getListData = () => {
      yncyArchive({ pageNo: pageNo.value, type: newsCategory.value === 0 ? '' : newsCategory.value }).then(res => {
        total.value = res.result.total
        list.value = res.result.records
      })
    }
    const changeCategory = (type) => {
      newsCategory.value = type
      getListData()
    }
    onMounted(() => {
      getListData()
    })

    const pageChange = (page) => {
      pageNo.value = page
      getListData()
    }

    const getType = (file) => {
      const filename = file
      const index1 = filename.lastIndexOf('.')
      const index2 = filename.length
      const type = filename.substring(index1, index2)
      return type
    }

    const getImgUrl = (url) => {
      const type = url && getType(url)

      let img
      switch (type) {
        case '.doc':
        case '.docx':
          img = doc
          break
        case '.pdf':
          img = pdf
          break
        case '.xls':
        case '.xlsx':
          img = xlsx
          break
        default:
          img = pdf
      }

      return img
    }

    return { list, pageNo, total, newsCategory, pageChange, getImgUrl, changeCategory }
  }
})
</script>

<style lang="scss" scoped>
.types{
  border-bottom: 1px solid #dddddd;
  margin-top: 30px;
  display: flex;
  margin-bottom: 30px;

  &>div{
    flex: 1;
    text-align: center;
    line-height: 50px;
    font-size: 22px;
    cursor: pointer;

    &.active{
      color: #07C160;
      border-bottom: 1px solid #07C160;
    }
  }
}
.list{
  margin-bottom: 40px;
  display: flex;
  flex-wrap: wrap;
}
.item{
  width: 352px;
  height: 120px;
  padding: 25px 16px;
  display: flex;
  margin-right: 72px;
  margin-bottom: 60px;
  border: 1px solid #DDDDDD;

  &:nth-child(3n){
    margin-right: 0;
  }
}
.title{
  height: 38px;
  font-size: 16px;
  margin-bottom: 8px;
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
.content{
  flex: 1;
}
.btn{
  width: 72px;
  height: 24px;
  line-height: 24px;
  border: 1px solid #DDDDDD;
  color: #07C160;

  a{
    display: flex;
    width: 70px;
    height: 22px;
    justify-content: center;
    align-items: center;
  }

  &:hover{
    background: #07C160;
    cursor: pointer;
    border: 1px solid #07C160;

    i{
      background: url(../assets/down-hover.png) no-repeat;
      background-size: 9px 12px;
    }

    a{
      color: white;
    }
  }

  i{
    display: block;
    width: 9px;
    height: 12px;
    margin-right: 6px;
    background: url(../assets/down.png) no-repeat;
    background-size: 9px 12px;
  }
}

.img{
  width: 56px;
  height: 69px;
  margin-right: 8px;
  img{
    width: 100%;
  }
}

</style>
